<template>
  <div class="block-detial-box">
    <el-card class="box-card" v-if="blockInfo">
      <div slot="header">
        <div class="card-header">
          {{$t('block.区块')}}
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('index.区块高度')}}:</div>
        <div class="value">
          <el-input-number
            v-model="blockNumber"
            size="small"
            controls-position="right"
            @change="currentChange"
            :min="1"
            :max="maxBlockNumber"
          ></el-input-number>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.生成时间')}}:</div>
        <div class="value">
          {{
            dateFormat(
              "yyyy-MM-dd hh:mm:ss",
              new Date(blockInfo.timestamp * 1000 || 0)
            )
          }}
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.交易次数')}}:</div>
        <div class="value">
          {{ blockInfo.transactionNum }}{{$t('block.个交易在这个区块中')}}
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.矿工')}}:</div>
        <div class="value">{{ blockInfo.miner }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.难度值')}}:</div>
        <div class="value">{{ blockInfo.difficulty }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.总难度值')}}:</div>
        <div class="value">{{ blockInfo.totalDifficulty }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.容量')}}:</div>
        <div class="value">{{ blockInfo.size }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.燃料限制')}}:</div>
        <div class="value">{{ blockInfo.gasLimit }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.额外数据')}}:</div>
        <div class="value">{{ blockInfo.extraData }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.哈希')}}:</div>
        <div class="value">{{ blockInfo.hash }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.父区块哈希')}}:</div>
        <div class="value">{{ blockInfo.parentHash }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">(Sha3){{$t('block.叔区块')}}:</div>
        <div class="value">{{ blockInfo.sha3Uncles }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">nonce:</div>
        <div class="value">{{ blockInfo.nonce }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getBlockInfo, getBlockNumber } from "@/api";
import { useI18n } from "vue-i18n"
export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n()
    const data = reactive({
      $t: t,
      blockNumber: Number(route.params.blockNumber),
      maxBlockNumber: 10000000000000,
      blockInfo: null,
      currentChange: number => {
        router.replace({
          name: "BlockDetial",
          params: { blockNumber: number }
        });
      }
    });
    onMounted(() => {
      getBlockNumber().then(res => {
        data.maxBlockNumber = res.data;
      });
      getBlockInfo({ blockNumber: data.blockNumber }).then(res => {
        data.blockInfo = res.data;
      });
    });
    onBeforeRouteUpdate((to, from) => {
      data.blockNumber = Number(to.params.blockNumber);
      data.blockInfo = null;
      getBlockInfo({ blockNumber: data.blockNumber }).then(res => {
        data.blockInfo = res.data;
      });
      getBlockNumber().then(res => {
        data.maxBlockNumber = res.data + "";
      });
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.item {
  font-size: 16px;
  color: #333;
  text-align: left;
  .title {
    width: 20%;
  }
  .value {
    flex: 1;
  }
}
</style>
